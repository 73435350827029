




































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Provide, Vue } from 'vue-property-decorator'
import { ChangeWalletViewModel } from '../view-models/change-wallet-viewmodel'

@Observer
@Component({
  components: {
    'wallet-setting-connect-dialog': () => import('@/components/auth/wallet-setting-connect-dialog.vue'),
  },
})
export default class ChangeWallet extends Vue {
  @Provide() vm = new ChangeWalletViewModel()
  walletStore = walletStore
}
