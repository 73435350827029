import { algoClient } from '@/algo-client'
import { appProvider } from '@/app-provider'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { walletStore } from '@/stores/wallet-store'
import { get } from 'lodash'
import { computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class ChangeWalletViewModel {
  constructor() {
    //
  }

  @observable newWalletAddress = ''
  @observable confirmNewWalletAddress = ''
  @observable currentWalletAddress = ''
  @observable loading = false
  @observable errorMessage = '';

  @asyncAction *changeWalletAddress() {
    try {
      this.loading = true
      if (this.newWalletAddress !== this.confirmNewWalletAddress) {
        this.errorMessage = 'Confirm new wallet is not match'
        return
      }
      const currentWalletInfo = yield algoClient.accountInformation(this.currentWalletAddress).do()
      const authWallet = get(currentWalletInfo, 'auth-addr')
      if (authWallet !== walletStore.account) {
        this.errorMessage = `Connected wallet is not auth wallet`
        return
      }
  
      yield apiService.profiles.changeWallet({
        currentWalletAddress: this.currentWalletAddress,
        newWalletAddress: this.newWalletAddress,
        confirmNewWalletAddress: this.confirmNewWalletAddress,
      })
      snackController.success("Change wallet successfully")
      walletStore.disconnect()
    } catch (error) {
      snackController.commonError(error)
    } finally {
      this.loading = false
    }
  }

  @computed get changeWalletEnable() {
    return this.newWalletAddress && this.confirmNewWalletAddress
  }
}
